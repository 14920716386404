import React, { useEffect, useState } from "react";
import "./inventory.scss";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import { MdOutlineEdit } from "react-icons/md";
import { RiDeleteBin5Line } from "react-icons/ri";
import { FaSearch } from "react-icons/fa";
import axios from "axios";
import TextSlide from "../../components/textSlide/TextSlide";
import Table from "../../components/table/Table";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import StarIcon from "@mui/icons-material/Star";
import { FaRegEye } from "react-icons/fa";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import List from "../list/List";

const Inventory = () => {
  const { itemId } = useParams();
  const [showAddNew, setShowAddNew] = useState(false);
  const [showPro, setShowPro] = useState(false);
  const [productData, setProductData] = useState([]);
  const [saveData, setSaveData] = useState([]);
  const [itemEditId, setItemEditId] = useState("");
  const [title, setTitle] = useState("");
  const [price, setPrice] = useState("");
  const [weight, setWeight] = useState("");
  const [category, setCategory] = useState("");
  const [description, setDescription] = useState("");
  const [sku, setSku] = useState("");
  const [img, setImg] = useState([]);
  const [brand, setBrand] = useState("");
  const [showEdit, setShowEdit] = useState(false);
  const [discount, setDiscount] = useState("");
  const [singalProductData, setSingalProductData] = useState([]);
  const [addedPhotos, setAddedPhotos] = useState([]);
  const [addedPhotosHD, setAddedPhotosHD] = useState([]);
  const [image, setImage] = useState([]);
  const navigate = useNavigate();
  const [subCategory, setSubCategory] = useState("");
  const [flavour, setFlavour] = useState("");
  const [barcodePouch, setBarcodePouch] = useState("");
  const [barcodeCase, setBarcodeCase] = useState("");
  const [uom, setUom] = useState("");
  const [crudeFiber, setCrudeFiber] = useState("");
  const [satureatedFat, setSatureatedFat] = useState("");
  const [protein, setProtein] = useState("");
  const [fat, setFat] = useState("");
  const [ctnWidthMM, setCtnWidthMM] = useState("");
  const [ctnHeightMM, setCtnHeightMM] = useState("");
  const [unit, setUnit] = useState("");
  const [ctnLengthMM, setCtnLengthMM] = useState("");
  const [base, setBase] = useState("");
  const [type, setType] = useState("");
  const [filling, setFilling] = useState("");
  const [brandOwner, setBrandOwner] = useState("");
  const [coating, setCoating] = useState("");
  const [wetRang, setWetRang] = useState("");
  const [nameEnglish, setNameEnglish] = useState("");
  const [nameArbic, setNameArbic] = useState("");
  const [claimsOne, setClaimsOne] = useState("");
  const [proDesEnglish, setProDesEnglish] = useState("");
  const [proDesArbic, setProDesArbic] = useState("");
  const [claimsTwo, setClaimsTwo] = useState("");
  const [claimsThree, setClaimsThree] = useState("");
  const [benefits, setBenefits] = useState("");
  const [carbohydrates, setCarbohydrates] = useState("");
  const [sugar, setSugar] = useState("");
  const [energy, setEnergy] = useState("");
  const [ingredients, setIngredients] = useState("");
  const [heightMM, setHeightMM] = useState("");
  const [widthMM, setWidthMM] = useState("");
  const [lengthMM, setLengthMM] = useState("");
  const [shelfLife, setShelfLife] = useState("");
  const [countryOfOrigin, setCountryOfOrigin] = useState("");
  const [storageInstructions, setStorageInstructions] = useState("");
  const [dimensionsPerPack, setDimensionsPerPack] = useState("");
  const [dimensionsPerCase, setDimensionsPerCase] = useState("");
  const [featuresAndOrBenefitsOne, setFeaturesAndOrBenefitsOne] = useState("");
  const [featuresAndOrBenefitsTwo, setFeaturesAndOrBenefitsTwo] = useState("");

  const onEditClick = (id) => {
    const data = id;
    setItemEditId(data);
  };

  const imageUrl = process.env.REACT_APP_UPLOAD_URL;

  const deleteItem = async () => {
    if (!itemId) {
      return;
    } else {
      await axios.delete(`/itemDelete/${itemId}`);
      setSaveData(Date.now());
      setShowPro("");
    }
  };

  // useEffect(() => {
  //   const deleteItem = async () => {
  //     if (!itemEditId) {
  //       return;
  //     } else {
  //       await axios.delete(`/itemDelete/${itemEditId}`);
  //       setSaveData(Date.now());
  //     }
  //   };
  //   deleteItem();
  // }, [itemEditId]);

  const handleAddNew = () => {
    setShowAddNew(!showAddNew);
  };
  const handleEdit = () => {
    setShowEdit(!showEdit);
    setShowPro("");
  };

  const handleProfile = () => {
    setShowPro(!showPro);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      title,
      price,
      weight,
      unit,
      type,
      category,
      subCategory,
      brand,
      brandOwner,
      base,
      filling,
      coating,
      wetRang,
      sku,
      nameEnglish,
      nameArbic,
      barcodePouch,
      barcodeCase,
      claimsOne,
      claimsTwo,
      claimsThree,
      benefits,
      proDesEnglish,
      proDesArbic,
      dimensionsPerCase,
      storageInstructions,
      shelfLife,
      widthMM,
      heightMM,
      lengthMM,
      ctnWidthMM,
      ctnHeightMM,
      ctnLengthMM,
      ingredients,
    };
    try {
      await axios.post("/saveProduct", data).then((response) => {
        setSaveData(response.data);
      });

      //clear form
      setUnit("");
      setType("");
      setShowAddNew("");
      setTitle("");
      setPrice("");
      setWeight("");
      setCategory("");
      setSku("");
      setBrand("");
      setClaimsOne("");
      setClaimsTwo("");
      setClaimsThree("");
      setBrandOwner("");
      setAddedPhotosHD("");
      setDiscount("");
      setDescription("");
      setAddedPhotos("");
      setSubCategory("");
      setNameEnglish("");
      setNameArbic("");
      setFilling("");
      setCoating("");
      setProDesEnglish("");
      setBarcodePouch("");
      setBarcodeCase("");
      setBenefits("");
      setCrudeFiber("");
      setSatureatedFat("");
      setProtein("");
      setFat("");
      setWetRang("");
      setCarbohydrates("");
      setSugar("");
      setEnergy("");
      setIngredients("");
      setHeightMM("");
      setWidthMM("");
      setLengthMM("");
      setCtnLengthMM("");
      setCtnHeightMM("");
      setCtnWidthMM("");
      setShelfLife("");
      setCountryOfOrigin("");
      setStorageInstructions("");
      setDimensionsPerPack("");
      setDimensionsPerCase("");
      setFeaturesAndOrBenefitsOne("");
      setFeaturesAndOrBenefitsTwo("");
    } catch (error) {
      console.log(error);
    }
  };

  async function uploadPhoto(e) {
    const files = e.target.files;
    const data = new FormData();

    for (let i = 0; i < files.length; i++) {
      data.append("photos", files[i]);
    }
    await axios
      .post("/upload", data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        const { data: filenames } = response;
        setAddedPhotos((prev) => {
          return [...prev, ...filenames];
        });
      });
  }

  async function uploadPhotoHD(e) {
    const files = e.target.files;
    const data = new FormData();

    for (let i = 0; i < files.length; i++) {
      data.append("photos", files[i]);
    }
    await axios
      .post("/uploadHD", data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        const { data: filenames } = response;
        setAddedPhotosHD((prev) => {
          return [...prev, ...filenames];
        });
      });
  }

  useEffect(() => {
    try {
      axios.get("/getProduct").then((response) => {
        setProductData(response.data);
      });
    } catch (error) {
      console.log(error);
    }
  }, [saveData]);

  useEffect(() => {
    if (!itemId) {
      setTitle("");
      setPrice("");
      setWeight("");
      setCategory("");
      setSku("");
      setBrandOwner("");
      setUnit("");
      setType("");
      setFilling("");
      setBrand("");
      setClaimsOne("");
      setAddedPhotosHD("");
      setDiscount("");
      setDescription("");
      setAddedPhotos("");
      setSubCategory("");
      setNameEnglish("");
      setNameArbic("");
      setProDesEnglish("");
      setBarcodePouch("");
      setBarcodeCase("");
      setBenefits("");
      setCrudeFiber("");
      setSatureatedFat("");
      setProtein("");
      setClaimsTwo("");
      setClaimsThree("");
      setFat("");
      setCarbohydrates("");
      setSugar("");
      setCtnLengthMM("");
      setCtnHeightMM("");
      setCtnWidthMM("");
      setEnergy("");
      setIngredients("");
      setWetRang("");
      setHeightMM("");
      setWidthMM("");
      setLengthMM("");
      setShelfLife("");
      setCountryOfOrigin("");
      setStorageInstructions("");
      setDimensionsPerPack("");
      setDimensionsPerCase("");
      setFeaturesAndOrBenefitsOne("");
      setFeaturesAndOrBenefitsTwo("");
      return;
    }
    
    axios.get(`/getSingalProduct/${itemId}`).then((response) => {
      setSingalProductData(response?.data);
      // Check if response.data is an array
      if (Array.isArray(response.data)) {
        // If it's an array, apply flatMap on it
        setImage(response.data.flatMap((item) => item.photos));
      } else {
        // Handle the case where response.data is not an array
        console.error("Response data is not an array:");
      }
    });
  }, [itemId]);

  function removePhoto(e, filename) {
    e.preventDefault();
    setAddedPhotos([...addedPhotos.filter((photo) => photo !== filename)]);
  }
  function selectMainPhoto(e, filename) {
    e.preventDefault();
    const addPhotoWithoutSelected = addedPhotos.filter(
      (photo) => photo !== filename
    );
    const newAddedPhotos = [filename, ...addPhotoWithoutSelected];
    setAddedPhotos(newAddedPhotos);
  }

  function removePhotoHD(e, filename) {
    e.preventDefault();
    setAddedPhotosHD([...addedPhotosHD.filter((photo) => photo !== filename)]);
  }
  function selectMainPhotoHD(e, filename) {
    e.preventDefault();
    const addPhotoWithoutSelected = addedPhotosHD.filter(
      (photo) => photo !== filename
    );
    const newAddedPhotos = [filename, ...addPhotoWithoutSelected];
    setAddedPhotosHD(newAddedPhotos);
  }

  useEffect(() => {
    if (!itemId) {
      return;
    }
    axios
      .get(`/updateSingleProduct/${itemId}`)
      .then((response) => {
        const { data } = response;
        //  console.log("data:", data);
        setTitle(data.title);
        setType(data.type);
        setPrice(data.price);
        setWeight(data.weight);
        setCategory(data.category);
        setSku(data.sku);
        setFilling(data.filling);
        setUnit(data.unit);
        setBrandOwner(data.brandOwner);
        setBrand(data.brand);
        setDiscount(data.discount);
        setDescription(data.description);
        setAddedPhotos(data.addedPhotos);
        setSubCategory(data.subCategory);
        setNameEnglish(data.nameEnglish);
        setNameArbic(data.nameArbic);
        setBarcodePouch(data.barcodePouch);
        setBarcodeCase(data.barcodeCase);
        setUom(data.uom);
        setProDesArbic(data.proDesArbic);
        setBenefits(data.benefits);
        setCrudeFiber(data.crudeFiber);
        setSatureatedFat(data.satureatedFat);
        setProtein(data.protein);
        setFat(data.fat);
        setProDesEnglish(data.proDesEnglish);
        setCoating(data.coating);
        setCarbohydrates(data.carbohydrates);
        setSugar(data.sugar);
        setWetRang(data.wetRang);
        setEnergy(data.energy);
        setClaimsOne(data.claimsOne);
        setClaimsTwo(data.claimsTwo);
        setClaimsThree(data.claimsThree);
        setIngredients(data.ingredients);
        setHeightMM(data.heightMM);
        setWidthMM(data.widthMM);
        setLengthMM(data.lengthMM);
        setCtnLengthMM(data.ctnLengthMM);
        setCtnHeightMM(data.ctnHeightMM);
        setCtnWidthMM(data.ctnWidthMM);
        setShelfLife(data.shelfLife);
        setCountryOfOrigin(data.countryOfOrigin);
        setStorageInstructions(data.storageInstructions);
        setDimensionsPerPack(data.dimensionsPerPack);
        setDimensionsPerCase(data.dimensionsPerCase);
        setFeaturesAndOrBenefitsOne(data.featuresAndOrBenefitsOne);
        setFeaturesAndOrBenefitsTwo(data.featuresAndOrBenefitsOne);

        // setAddedPhotos(data.flatMap((item) => item.photos));
      })
      .catch((error) => {
        console.error("Error fetching product:", error);
      });
  }, [itemEditId, showEdit]);

  // Define columns for the DataGrid
  const columns = [
    { field: "sku", headerName: "LR Code", width: 200 },
    { field: "title", headerName: "Title", width: 300 },
    { field: "brand", headerName: "Brand", width: 200 },
    { field: "category", headerName: "Category", width: 200 },
    {
      field: "discount",
      headerName: "Discount",
    },
    {
      field: "price",
      headerName: "Price",
    },
    {
      field: "weight",
      headerName: "Weight",
    },
  ];

  const handleCellClick = (params) => {
    const placesId = params.row?._id;
    const url = `/inventory/${placesId}`;
    navigate(url);
    setShowPro(!showPro);
  };

  const updateProdutItem = async (e) => {
    e.preventDefault();
    const data = {
      title,
      price,
      weight,
      unit,
      type,
      category,
      subCategory,
      brand,
      brandOwner,
      base,
      filling,
      coating,
      wetRang,
      sku,
      nameEnglish,
      nameArbic,
      barcodePouch,
      barcodeCase,
      claimsOne,
      claimsTwo,
      claimsThree,
      benefits,
      proDesEnglish,
      proDesArbic,
      dimensionsPerCase,
      storageInstructions,
      shelfLife,
      widthMM,
      heightMM,
      lengthMM,
      ctnWidthMM,
      ctnHeightMM,
      ctnLengthMM,
      ingredients,
      addedPhotos,
      addedPhotosHD,
    };
    try {
      await axios
        .put(`/updateSingleProduct/${itemId}`, data)
        .then((response) => {
          setSaveData(response.data);
          setShowEdit("");
        });

      //clear form
    } catch (error) {
      console.log(error);
    }
  };

  //! download img

  const handleDownload = (imageUrl) => {
    const link = document.createElement("a");
    link.href = imageUrl;
    link.download = "image.jpg";
    link.target = "_blank";
    document.body.appendChild(link);
    link.click();
    // console.log("Click event triggered:", link.onclick !== null);
    document.body.removeChild(link);
  };

  return (
    <div className="inventory_main">
      <div className="inventory">
        {/* <div className="inventory_head">
          <p>Overall Inventory</p>
        </div> */}
        <div className="table">
          <div className="table_head">
            <div className="search"></div>
            <div className="addNew">
              <span onClick={handleAddNew}>Add New</span>
              {!itemId ? (
                <>
                  {showAddNew && (
                    <div className="addNew_in">
                      <div className="addNew_wrap">
                        <h2>Add New Product</h2>
                        <br />
                        <form onSubmit={handleSubmit}>
                          <div className="add_form">
                            <div className="add_form_wrap">
                              <div>
                                <div className="input">
                                  <label>01. Title</label>
                                  <input
                                    type="text"
                                    placeholder="Chocodate"
                                    value={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                  />
                                </div>
                                <div className="input">
                                  <label>Price</label>
                                  <input
                                    type="text"
                                    placeholder="100 AED"
                                    value={price}
                                    onChange={(e) => setPrice(e.target.value)}
                                  />
                                </div>
                                <div className="input">
                                  <label>Weight</label>
                                  <input
                                    type="text"
                                    placeholder="500g"
                                    value={weight}
                                    onChange={(e) => setWeight(e.target.value)}
                                  />
                                </div>
                                <div className="input">
                                  <label>Unit</label>
                                  <select
                                    value={unit}
                                    onChange={(e) => setUnit(e.target.value)}
                                  >
                                    <option value="">Select</option>
                                    <option value="ACRYLIC">ACRYLIC</option>
                                    <option value="BOX">BOX</option>
                                    <option value="BULK">BULK</option>
                                    <option value="CUBE">CUBE</option>
                                    <option value="JAR">JAR</option>
                                    <option value="POUCH">POUCH</option>
                                    <option value="T BOX">T BOX</option>
                                  </select>
                                </div>

                                <div className="input">
                                  <label>Type</label>
                                  <select
                                    value={type}
                                    onChange={(e) => setType(e.target.value)}
                                  >
                                    <option value="">Select</option>
                                    <option value="BULK">BULK</option>
                                    <option value="PROM">PROM</option>
                                    <option value="REG">REG</option>
                                  </select>
                                </div>

                                <div className="input">
                                  <label>Category</label>
                                  <select
                                    value={category}
                                    onChange={(e) =>
                                      setCategory(e.target.value)
                                    }
                                  >
                                    <option value="">Select</option>
                                    <option value="BAKING">BAKING</option>
                                    <option value="CHOCOLATE">CHOCOLATE</option>
                                    <option value="CHOCOLATE DATES">
                                      CHOCOLATE DATES
                                    </option>
                                    <option value="COATEED NUTS">
                                      COATEED NUTS
                                    </option>
                                    <option value="ENERGY & PROTIEN">
                                      ENERGY & PROTIEN
                                    </option>
                                    <option value="PLAIN DATE">
                                      PLAIN DATE
                                    </option>
                                  </select>
                                </div>

                                <div className="input">
                                  <label>Sub Category</label>
                                  <select
                                    value={subCategory}
                                    onChange={(e) =>
                                      setSubCategory(e.target.value)
                                    }
                                  >
                                    <option value="">Select</option>
                                    <option value="BUTTER">BUTTER</option>
                                    <option value="CHOCOLATE">CHOCOLATE</option>
                                    <option value="CHOCOLATE APRICOT">
                                      CHOCOLATE APRICOT
                                    </option>
                                    <option value="CHOCOLATE CHIPS">
                                      CHOCOLATE CHIPS
                                    </option>
                                    <option value="CHOCOLATE FIG">
                                      CHOCOLATE FIG
                                    </option>
                                    <option value="COATED NUTS">
                                      COATED-NUTS
                                    </option>
                                    <option value="DATE CHOCO">
                                      DATE-CHOCO
                                    </option>
                                    <option value="FLOUR">FLOUR</option>
                                    <option value="PLAIN DATE">
                                      PLAIN DATE
                                    </option>
                                    <option value="PROTEIN BALL">
                                      PROTEIN BALL
                                    </option>
                                    <option value="SUGAR">SUGAR</option>
                                  </select>
                                </div>

                                <div className="input">
                                  <label>Brand</label>
                                  <select
                                    value={brand}
                                    onChange={(e) => setBrand(e.target.value)}
                                  >
                                    <option value="">Select</option>
                                    <option value="ALDI">ALDI</option>
                                    <option value="APRONS N LOVE">
                                      APRONS N LOVE
                                    </option>
                                    <option value="ARABIAN DELIGHTS">
                                      ARABIAN DELIGHTS
                                    </option>
                                    <option value="ATT">ATT</option>
                                    <option value="CARREFOUR">CARREFOUR</option>
                                    <option value="CHOCODATE">CHOCODATE</option>
                                    <option value="DARA">DARA</option>
                                    <option value="FADE FIT">FADE FIT</option>
                                    <option value="FIESTA">FIESTA</option>
                                    <option value="HERITAGE">HERITAGE</option>
                                    <option value="KITCHEN & LOVE">
                                      KITCHEN & LOVE
                                    </option>
                                    <option value="LULU">LULU</option>
                                    <option value="MARS">MARS</option>
                                    <option value="MOJO">MOJO</option>
                                    <option value="OTHERS">OTHERS</option>
                                    <option value="ROUGE">ROUGE</option>
                                    <option value="SPINNEYS">SPINNEYS</option>
                                    <option value="VENICE">VENICE</option>
                                    <option value="WISHES">WISHES</option>
                                    <option value="ZEIN">ZEIN</option>
                                  </select>
                                </div>

                                <div className="input">
                                  <label>Brand Owner</label>
                                  <select
                                    value={brandOwner}
                                    onChange={(e) =>
                                      setBrandOwner(e.target.value)
                                    }
                                  >
                                    <option value="">Select</option>
                                    <option value="NOTIONS">NOTIONS</option>
                                    <option value="PRIVATE LABEL">
                                      PRIVATE LABEL
                                    </option>
                                  </select>
                                </div>

                                <div className="input">
                                  <label>Base</label>
                                  <select
                                    value={base}
                                    onChange={(e) => setBase(e.target.value)}
                                  >
                                    <option value="">Select</option>
                                    <option value="ALMOND">ALMOND</option>
                                    <option value="APRICOT">APRICOT</option>
                                    <option value="BUTTER">BUTTER</option>
                                    <option value="CHOCOLATE">CHOCOLATE</option>
                                    <option value="CHOCOLATE CHIPS">
                                      CHOCOLATE CHIPS
                                    </option>
                                    <option value="DATE">DATE</option>
                                    <option value="DATE PASTE">
                                      DATE PASTE
                                    </option>
                                    <option value="DATES">DATES</option>
                                    <option value="FIG">FIG</option>
                                    <option value="FLOUR">FLOUR</option>
                                    <option value="HAZELNUT">HAZELNUT</option>
                                    <option value="PLAIN">PLAIN</option>
                                    <option value="PROTEIN BALL">
                                      PROTEIN BALL
                                    </option>
                                    <option value="SUGAR">SUGAR</option>
                                  </select>
                                </div>

                                <div className="input">
                                  <label>Filling </label>
                                  <select
                                    value={filling}
                                    onChange={(e) => setFilling(e.target.value)}
                                  >
                                    <option value="">Select</option>
                                    <option value="0">0</option>
                                    <option value="ALMOND">ALMOND</option>
                                    <option value="ASSORTED">ASSORTED</option>
                                    <option value="BERRY">BERRY</option>
                                    <option value="CARAMEL">CARAMEL</option>
                                    <option value="CHOCOLATE CHIPS">
                                      CHOCOLATE CHIPS
                                    </option>
                                    <option value="CHOCOLATE">CHOCOLATE</option>
                                    <option value="CHOCOLATES">
                                      CHOCOLATES
                                    </option>
                                    <option value="COCONUT">COCONUT</option>
                                    <option value="FRUITS">FRUITS</option>
                                    <option value="HAZELNUTS">HAZELNUTS</option>
                                    <option value="HAZELNUT">HAZELNUT</option>
                                    <option value="MOUSSE">MOUSSE</option>
                                    <option value="NUTS">NUTS</option>
                                    <option value="ORANGE">ORANGE</option>
                                    <option value="PEANUT">PEANUT</option>
                                    <option value="PEANUT BUTTER">
                                      PEANUT BUTTER
                                    </option>
                                    <option value="PISTACHIO">PISTACHIO</option>
                                    <option value="PLAIN">PLAIN</option>
                                    <option value="TRUFFLE">TRUFFLE</option>
                                  </select>
                                </div>

                                <div className="input">
                                  <label>Coating </label>
                                  <select
                                    value={coating}
                                    onChange={(e) => setCoating(e.target.value)}
                                  >
                                    <option value="">Select</option>
                                    <option value="ALMOND">ALMOND</option>
                                    <option value="ASSORTED">ASSORTED</option>
                                    <option value="BUTTERSCOTCH">
                                      BUTTERSCOTCH
                                    </option>
                                    <option value="CARAMEL">CARAMEL</option>
                                    <option value="DARK">DARK</option>
                                    <option value="EXTRA DARK">
                                      EXTRA DARK
                                    </option>
                                    <option value="HAZELNUT">HAZELNUT</option>
                                    <option value="COCONUT">COCONUT</option>
                                    <option value="MANGO">MANGO</option>
                                    <option value="MILK">MILK</option>
                                    <option value="NAS">NAS</option>
                                    <option value="ORANGE">ORANGE</option>
                                    <option value="PEANUT">PEANUT</option>
                                    <option value="PISTACHIO">PISTACHIO</option>
                                    <option value="PLAIN">PLAIN</option>
                                    <option value="RUBY">RUBY</option>
                                    <option value="SEMI SWEET">
                                      SEMI SWEET
                                    </option>

                                    <option value="WHITE">WHITE</option>
                                  </select>
                                </div>
                                <div className="input">
                                  <label>WET Rang</label>
                                  <select
                                    value={wetRang}
                                    onChange={(e) => setWetRang(e.target.value)}
                                  >
                                    <option value="">Select</option>
                                    <option value="BULK">BULK</option>
                                    <option value="L">L</option>
                                    <option value="M">M</option>
                                    <option value="MINI">MINI</option>
                                    <option value="S">S</option>
                                  </select>
                                </div>

                                <div className="input">
                                  <label>SKU</label>
                                  <input
                                    type="text"
                                    placeholder="LR 123456"
                                    value={sku}
                                    onChange={(e) => setSku(e.target.value)}
                                  />
                                </div>

                                <div className="input">
                                  <label>SKU Name English</label>
                                  <input
                                    type="text"
                                    placeholder=""
                                    value={nameEnglish}
                                    onChange={(e) =>
                                      setNameEnglish(e.target.value)
                                    }
                                  />
                                </div>
                                <div className="input">
                                  <label>SKU Name Arbic</label>
                                  <input
                                    type="text"
                                    placeholder=""
                                    value={nameArbic}
                                    onChange={(e) =>
                                      setNameArbic(e.target.value)
                                    }
                                  />
                                </div>
                                <div className="input">
                                  <label>Barcode - Pouch</label>
                                  <input
                                    type="text"
                                    placeholder=""
                                    value={barcodePouch}
                                    onChange={(e) =>
                                      setBarcodePouch(e.target.value)
                                    }
                                  />
                                </div>
                                <div className="input">
                                  <label>Barcode - Case</label>
                                  <input
                                    type="text"
                                    placeholder=""
                                    value={barcodeCase}
                                    onChange={(e) =>
                                      setBarcodeCase(e.target.value)
                                    }
                                  />
                                </div>
                                <div className="input">
                                  <label>Claims 1</label>
                                  <input
                                    type="text"
                                    placeholder=""
                                    value={claimsOne}
                                    onChange={(e) =>
                                      setClaimsOne(e.target.value)
                                    }
                                  />
                                </div>
                                <div className="input">
                                  <label>Claims 2</label>
                                  <input
                                    type="text"
                                    placeholder=""
                                    value={claimsTwo}
                                    onChange={(e) =>
                                      setClaimsTwo(e.target.value)
                                    }
                                  />
                                </div>
                                <div className="input">
                                  <label>Claims 3</label>
                                  <input
                                    type="text"
                                    placeholder=""
                                    value={claimsThree}
                                    onChange={(e) =>
                                      setClaimsThree(e.target.value)
                                    }
                                  />
                                </div>

                                <div className="input">
                                  <label>Benefits </label>
                                  <input
                                    type="text"
                                    placeholder=""
                                    value={benefits}
                                    onChange={(e) =>
                                      setBenefits(e.target.value)
                                    }
                                  />
                                </div>
                                <div className="input">
                                  <label>Product description English</label>
                                  <input
                                    type="text"
                                    placeholder=""
                                    value={proDesEnglish}
                                    onChange={(e) =>
                                      setProDesEnglish(e.target.value)
                                    }
                                  />
                                </div>

                                <div className="input">
                                  <label>Product Description Arbic</label>
                                  <textarea
                                    type="text"
                                    placeholder="Savor the trio of decadence with our"
                                    value={proDesArbic}
                                    onChange={(e) =>
                                      setProDesArbic(e.target.value)
                                    }
                                  />
                                </div>

                                <div className="input">
                                  <label>Dimensions per Case</label>
                                  <input
                                    type="text"
                                    placeholder=""
                                    value={dimensionsPerCase}
                                    onChange={(e) =>
                                      setDimensionsPerCase(e.target.value)
                                    }
                                  />
                                </div>

                                <div className="input">
                                  <label>Storage Instructions</label>
                                  <textarea
                                    type="text"
                                    placeholder=""
                                    value={storageInstructions}
                                    onChange={(e) =>
                                      setStorageInstructions(e.target.value)
                                    }
                                  />
                                </div>

                                <div className="input">
                                  <label>Shelf Life</label>
                                  <input
                                    type="text"
                                    placeholder=""
                                    value={shelfLife}
                                    onChange={(e) =>
                                      setShelfLife(e.target.value)
                                    }
                                  />
                                </div>
                                <div className="form_in">
                                  <div className="input">
                                    <label>Unit Width (mm)</label>
                                    <input
                                      type="text"
                                      placeholder=""
                                      value={widthMM}
                                      onChange={(e) =>
                                        setWidthMM(e.target.value)
                                      }
                                    />
                                  </div>
                                  <div className="input">
                                    <label>Unit Height (mm)</label>
                                    <input
                                      type="text"
                                      placeholder=""
                                      value={heightMM}
                                      onChange={(e) =>
                                        setHeightMM(e.target.value)
                                      }
                                    />
                                  </div>
                                  <div className="input">
                                    <label>Unit Length (mm)</label>
                                    <input
                                      type="text"
                                      placeholder=""
                                      value={lengthMM}
                                      onChange={(e) =>
                                        setLengthMM(e.target.value)
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="form_in">
                                  <div className="input">
                                    <label>CTN Width (mm)</label>
                                    <input
                                      type="text"
                                      placeholder=""
                                      value={ctnWidthMM}
                                      onChange={(e) =>
                                        setCtnWidthMM(e.target.value)
                                      }
                                    />
                                  </div>
                                  <div className="input">
                                    <label>CTN Height (mm)</label>
                                    <input
                                      type="text"
                                      placeholder=""
                                      value={ctnHeightMM}
                                      onChange={(e) =>
                                        setCtnHeightMM(e.target.value)
                                      }
                                    />
                                  </div>
                                  <div className="input">
                                    <label>CTN Length (mm)</label>
                                    <input
                                      type="text"
                                      placeholder=""
                                      value={ctnLengthMM}
                                      onChange={(e) =>
                                        setCtnLengthMM(e.target.value)
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="input">
                                  <label>Ingredients</label>
                                  <input
                                    type="text"
                                    placeholder=""
                                    value={ingredients}
                                    onChange={(e) =>
                                      setIngredients(e.target.value)
                                    }
                                  />
                                </div>
                              </div>
                              <div>
                                <div className="input">
                                  <h2>Only e-Com images upload below</h2>
                                  <br />
                                  <div className="photo_grid">
                                    {addedPhotos.length > 0 &&
                                      addedPhotos.map((item, index) => (
                                        <div className="img_over" key={index}>
                                          <img
                                            src={`https://api-master.notionsgroup.com/uploads/${item}`}
                                            alt=""
                                          />
                                          {/* <img
                                            src={`${imageUrl}/${item}`}
                                            alt=""
                                          /> */}

                                          <div className="trash_icon">
                                            <button
                                              onClick={(e) =>
                                                removePhoto(e, item)
                                              }
                                            >
                                              <DeleteOutlineIcon
                                                style={{ fontSize: "medium" }}
                                              />
                                            </button>
                                          </div>
                                          <div className="star_icon">
                                            <button
                                              onClick={(e) =>
                                                selectMainPhoto(e, item)
                                              }
                                            >
                                              {item === addedPhotos[0] && (
                                                <StarIcon
                                                  style={{
                                                    fontSize: "medium",
                                                    color: "orange",
                                                  }}
                                                />
                                              )}
                                              {item !== addedPhotos[0] && (
                                                <StarBorderIcon
                                                  style={{ fontSize: "medium" }}
                                                />
                                              )}
                                            </button>
                                          </div>
                                        </div>
                                      ))}
                                    <label>
                                      <input
                                        onChange={uploadPhoto}
                                        type="file"
                                        multiple
                                        className="btn_upload"
                                      />
                                      Upload Images
                                    </label>
                                  </div>
                                </div>
                              </div>

                              <div>
                                <h2>Only HD images upload below</h2>
                                <br />
                                <div className="input">
                                  <div className="photo_grid">
                                    {addedPhotosHD.length > 0 &&
                                      addedPhotosHD.map((item, index) => (
                                        <div className="img_over" key={index}>
                                          <img
                                            src={`https://api-master.notionsgroup.com/HDuploads/${item}`}
                                            alt=""
                                          />

                                          <div className="trash_icon">
                                            <button
                                              onClick={(e) =>
                                                removePhotoHD(e, item)
                                              }
                                            >
                                              <DeleteOutlineIcon
                                                style={{ fontSize: "medium" }}
                                              />
                                            </button>
                                          </div>
                                          <div className="star_icon">
                                            <button
                                              onClick={(e) =>
                                                selectMainPhotoHD(e, item)
                                              }
                                            >
                                              {item === addedPhotosHD[0] && (
                                                <StarIcon
                                                  style={{
                                                    fontSize: "medium",
                                                    color: "orange",
                                                  }}
                                                />
                                              )}
                                              {item !== addedPhotosHD[0] && (
                                                <StarBorderIcon
                                                  style={{ fontSize: "medium" }}
                                                />
                                              )}
                                            </button>
                                          </div>
                                        </div>
                                      ))}
                                    <label>
                                      <input
                                        onChange={uploadPhotoHD}
                                        type="file"
                                        multiple
                                        className="btn_upload"
                                      />
                                      Upload HD Images
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="save">
                              <button>
                                <span>Save</span>
                              </button>
                            </div>
                          </div>
                          <Link to={"/inventory"}>
                            <div className="close">
                              <span onClick={handleAddNew}>X</span>
                            </div>
                          </Link>
                        </form>
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <>
                  {showEdit && (
                    <div className="addNew_in_edit">
                      <div className="addNew_wrap">
                        <h2>Update Product</h2>
                        <br />
                        <form onSubmit={updateProdutItem}>
                          <div className="add_form">
                            <div className="add_form_wrap">
                              <div className="grid_form">
                                <div className="input">
                                  <label>Title</label>
                                  <input
                                    type="text"
                                    placeholder="Chocodate"
                                    value={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                  />
                                </div>
                                <div className="input">
                                  <label>Price</label>
                                  <input
                                    type="text"
                                    placeholder="100 AED"
                                    value={price}
                                    onChange={(e) => setPrice(e.target.value)}
                                  />
                                </div>
                                <div className="input">
                                  <label>Weight</label>
                                  <input
                                    type="text"
                                    placeholder="500g"
                                    value={weight}
                                    onChange={(e) => setWeight(e.target.value)}
                                  />
                                </div>
                                <div className="input">
                                  <label>Unit</label>
                                  <select
                                    value={unit}
                                    onChange={(e) => setUnit(e.target.value)}
                                  >
                                    <option value="">Select</option>
                                    <option value="ACRYLIC">ACRYLIC</option>
                                    <option value="BOX">BOX</option>
                                    <option value="BULK">BULK</option>
                                    <option value="CUBE">CUBE</option>
                                    <option value="JAR">JAR</option>
                                    <option value="POUCH">POUCH</option>
                                    <option value="T BOX">T BOX</option>
                                  </select>
                                </div>

                                <div className="input">
                                  <label>Type</label>
                                  <select
                                    value={type}
                                    onChange={(e) => setType(e.target.value)}
                                  >
                                    <option value="">Select</option>
                                    <option value="BULK">BULK</option>
                                    <option value="PROM">PROM</option>
                                    <option value="REG">REG</option>
                                  </select>
                                </div>

                                <div className="input">
                                  <label>Category</label>
                                  <select
                                    value={category}
                                    onChange={(e) =>
                                      setCategory(e.target.value)
                                    }
                                  >
                                    <option value="">Select</option>
                                    <option value="BAKING">BAKING</option>
                                    <option value="CHOCOLATE">CHOCOLATE</option>
                                    <option value="CHOCOLATE DATES">
                                      CHOCOLATE DATES
                                    </option>
                                    <option value="COATEED NUTS">
                                      COATEED NUTS
                                    </option>
                                    <option value="ENERGY & PROTIEN">
                                      ENERGY & PROTIEN
                                    </option>
                                    <option value="PLAIN DATE">
                                      PLAIN DATE
                                    </option>
                                  </select>
                                </div>

                                <div className="input">
                                  <label>Sub Category</label>
                                  <select
                                    value={subCategory}
                                    onChange={(e) =>
                                      setSubCategory(e.target.value)
                                    }
                                  >
                                    <option value="">Select</option>
                                    <option value="BUTTER">BUTTER</option>
                                    <option value="CHOCOLATE">CHOCOLATE</option>
                                    <option value="CHOCOLATE APRICOT">
                                      CHOCOLATE APRICOT
                                    </option>
                                    <option value="CHOCOLATE CHIPS">
                                      CHOCOLATE CHIPS
                                    </option>
                                    <option value="CHOCOLATE FIG">
                                      CHOCOLATE FIG
                                    </option>
                                    <option value="COATED NUTS">
                                      COATED-NUTS
                                    </option>
                                    <option value="DATE CHOCO">
                                      DATE-CHOCO
                                    </option>
                                    <option value="FLOUR">FLOUR</option>
                                    <option value="PLAIN DATE">
                                      PLAIN DATE
                                    </option>
                                    <option value="PROTEIN BALL">
                                      PROTEIN BALL
                                    </option>
                                    <option value="SUGAR">SUGAR</option>
                                  </select>
                                </div>

                                <div className="input">
                                  <label>Brand</label>
                                  <select
                                    value={brand}
                                    onChange={(e) => setBrand(e.target.value)}
                                  >
                                    <option value="">Select</option>
                                    <option value="ALDI">ALDI</option>
                                    <option value="APRONS N LOVE">
                                      APRONS N LOVE
                                    </option>
                                    <option value="ARABIAN DELIGHTS">
                                      ARABIAN DELIGHTS
                                    </option>
                                    <option value="ATT">ATT</option>
                                    <option value="CARREFOUR">CARREFOUR</option>
                                    <option value="CHOCODATE">CHOCODATE</option>
                                    <option value="DARA">DARA</option>
                                    <option value="FADE FIT">FADE FIT</option>
                                    <option value="FIESTA">FIESTA</option>
                                    <option value="HERITAGE">HERITAGE</option>
                                    <option value="KITCHEN & LOVE">
                                      KITCHEN & LOVE
                                    </option>
                                    <option value="LULU">LULU</option>
                                    <option value="MARS">MARS</option>
                                    <option value="MOJO">MOJO</option>
                                    <option value="OTHERS">OTHERS</option>
                                    <option value="ROUGE">ROUGE</option>
                                    <option value="SPINNEYS">SPINNEYS</option>
                                    <option value="VENICE">VENICE</option>
                                    <option value="WISHES">WISHES</option>
                                    <option value="ZEIN">ZEIN</option>
                                  </select>
                                </div>

                                <div className="input">
                                  <label>Brand Owner</label>
                                  <select
                                    value={brandOwner}
                                    onChange={(e) =>
                                      setBrandOwner(e.target.value)
                                    }
                                  >
                                    <option value="">Select</option>
                                    <option value="NOTIONS">NOTIONS</option>
                                    <option value="PRIVATE LABEL">
                                      PRIVATE LABEL
                                    </option>
                                  </select>
                                </div>

                                <div className="input">
                                  <label>Base</label>
                                  <select
                                    value={base}
                                    onChange={(e) => setBase(e.target.value)}
                                  >
                                    <option value="">Select</option>
                                    <option value="ALMOND">ALMOND</option>
                                    <option value="APRICOT">APRICOT</option>
                                    <option value="BUTTER">BUTTER</option>
                                    <option value="CHOCOLATE">CHOCOLATE</option>
                                    <option value="CHOCOLATE CHIPS">
                                      CHOCOLATE CHIPS
                                    </option>
                                    <option value="DATE">DATE</option>
                                    <option value="DATE PASTE">
                                      DATE PASTE
                                    </option>
                                    <option value="DATES">DATES</option>
                                    <option value="FIG">FIG</option>
                                    <option value="FLOUR">FLOUR</option>
                                    <option value="HAZELNUT">HAZELNUT</option>
                                    <option value="PLAIN">PLAIN</option>
                                    <option value="PROTEIN BALL">
                                      PROTEIN BALL
                                    </option>
                                    <option value="SUGAR">SUGAR</option>
                                  </select>
                                </div>

                                <div className="input">
                                  <label>Filling </label>
                                  <select
                                    value={filling}
                                    onChange={(e) => setFilling(e.target.value)}
                                  >
                                    <option value="">Select</option>
                                    <option value="0">0</option>
                                    <option value="ALMOND">ALMOND</option>
                                    <option value="ASSORTED">ASSORTED</option>
                                    <option value="BERRY">BERRY</option>
                                    <option value="CARAMEL">CARAMEL</option>
                                    <option value="CHOCOLATE CHIPS">
                                      CHOCOLATE CHIPS
                                    </option>
                                    <option value="CHOCOLATE">CHOCOLATE</option>
                                    <option value="CHOCOLATES">
                                      CHOCOLATES
                                    </option>
                                    <option value="COCONUT">COCONUT</option>
                                    <option value="FRUITS">FRUITS</option>
                                    <option value="HAZELNUTS">HAZELNUTS</option>
                                    <option value="HAZELNUT">HAZELNUT</option>
                                    <option value="MOUSSE">MOUSSE</option>
                                    <option value="NUTS">NUTS</option>
                                    <option value="ORANGE">ORANGE</option>
                                    <option value="PEANUT">PEANUT</option>
                                    <option value="PEANUT BUTTER">
                                      PEANUT BUTTER
                                    </option>
                                    <option value="PISTACHIO">PISTACHIO</option>
                                    <option value="PLAIN">PLAIN</option>
                                    <option value="TRUFFLE">TRUFFLE</option>
                                  </select>
                                </div>

                                <div className="input">
                                  <label>Coating </label>
                                  <select
                                    value={coating}
                                    onChange={(e) => setCoating(e.target.value)}
                                  >
                                    <option value="">Select</option>
                                    <option value="ALMOND">ALMOND</option>
                                    <option value="ASSORTED">ASSORTED</option>
                                    <option value="BUTTERSCOTCH">
                                      BUTTERSCOTCH
                                    </option>
                                    <option value="CARAMEL">CARAMEL</option>
                                    <option value="DARK">DARK</option>
                                    <option value="EXTRA DARK">
                                      EXTRA DARK
                                    </option>
                                    <option value="HAZELNUT">HAZELNUT</option>
                                    <option value="COCONUT">COCONUT</option>
                                    <option value="MANGO">MANGO</option>
                                    <option value="MILK">MILK</option>
                                    <option value="NAS">NAS</option>
                                    <option value="ORANGE">ORANGE</option>
                                    <option value="PEANUT">PEANUT</option>
                                    <option value="PISTACHIO">PISTACHIO</option>
                                    <option value="PLAIN">PLAIN</option>
                                    <option value="RUBY">RUBY</option>
                                    <option value="SEMI SWEET">
                                      SEMI SWEET
                                    </option>

                                    <option value="WHITE">WHITE</option>
                                  </select>
                                </div>
                                <div className="input">
                                  <label>WET Rang</label>
                                  <select
                                    value={wetRang}
                                    onChange={(e) => setWetRang(e.target.value)}
                                  >
                                    <option value="">Select</option>
                                    <option value="BULK">BULK</option>
                                    <option value="L">L</option>
                                    <option value="M">M</option>
                                    <option value="MINI">MINI</option>
                                    <option value="S">S</option>
                                  </select>
                                </div>

                                <div className="input">
                                  <label>SKU</label>
                                  <input
                                    type="text"
                                    placeholder="LR 123456"
                                    value={sku}
                                    onChange={(e) => setSku(e.target.value)}
                                  />
                                </div>

                                <div className="input">
                                  <label>SKU Name English</label>
                                  <input
                                    type="text"
                                    placeholder=""
                                    value={nameEnglish}
                                    onChange={(e) =>
                                      setNameEnglish(e.target.value)
                                    }
                                  />
                                </div>
                                <div className="input">
                                  <label>SKU Name Arbic</label>
                                  <input
                                    type="text"
                                    placeholder=""
                                    value={nameArbic}
                                    onChange={(e) =>
                                      setNameArbic(e.target.value)
                                    }
                                  />
                                </div>
                                <div className="input">
                                  <label>Barcode - Pouch</label>
                                  <input
                                    type="text"
                                    placeholder=""
                                    value={barcodePouch}
                                    onChange={(e) =>
                                      setBarcodePouch(e.target.value)
                                    }
                                  />
                                </div>
                                <div className="input">
                                  <label>Barcode - Case</label>
                                  <input
                                    type="text"
                                    placeholder=""
                                    value={barcodeCase}
                                    onChange={(e) =>
                                      setBarcodeCase(e.target.value)
                                    }
                                  />
                                </div>
                                <div className="input">
                                  <label>Claims 1</label>
                                  <input
                                    type="text"
                                    placeholder=""
                                    value={claimsOne}
                                    onChange={(e) =>
                                      setClaimsOne(e.target.value)
                                    }
                                  />
                                </div>
                                <div className="input">
                                  <label>Claims 2</label>
                                  <input
                                    type="text"
                                    placeholder=""
                                    value={claimsTwo}
                                    onChange={(e) =>
                                      setClaimsTwo(e.target.value)
                                    }
                                  />
                                </div>
                                <div className="input">
                                  <label>Claims 3</label>
                                  <input
                                    type="text"
                                    placeholder=""
                                    value={claimsThree}
                                    onChange={(e) =>
                                      setClaimsThree(e.target.value)
                                    }
                                  />
                                </div>

                                <div className="input">
                                  <label>Benefits </label>
                                  <input
                                    type="text"
                                    placeholder=""
                                    value={benefits}
                                    onChange={(e) =>
                                      setBenefits(e.target.value)
                                    }
                                  />
                                </div>
                                <div className="input">
                                  <label>Product description English</label>
                                  <input
                                    type="text"
                                    placeholder=""
                                    value={proDesEnglish}
                                    onChange={(e) =>
                                      setProDesEnglish(e.target.value)
                                    }
                                  />
                                </div>

                                <div className="input">
                                  <label>Product Description Arbic</label>
                                  <textarea
                                    type="text"
                                    placeholder="Savor the trio of decadence with our"
                                    value={proDesArbic}
                                    onChange={(e) =>
                                      setProDesArbic(e.target.value)
                                    }
                                  />
                                </div>

                                <div className="input">
                                  <label>Dimensions per Case</label>
                                  <input
                                    type="text"
                                    placeholder=""
                                    value={dimensionsPerCase}
                                    onChange={(e) =>
                                      setDimensionsPerCase(e.target.value)
                                    }
                                  />
                                </div>

                                <div className="input">
                                  <label>Storage Instructions</label>
                                  <textarea
                                    type="text"
                                    placeholder=""
                                    value={storageInstructions}
                                    onChange={(e) =>
                                      setStorageInstructions(e.target.value)
                                    }
                                  />
                                </div>

                                <div className="input">
                                  <label>Shelf Life</label>
                                  <input
                                    type="text"
                                    placeholder=""
                                    value={shelfLife}
                                    onChange={(e) =>
                                      setShelfLife(e.target.value)
                                    }
                                  />
                                </div>

                                <div className="input">
                                  <label>Unit Width (mm)</label>
                                  <input
                                    type="text"
                                    placeholder=""
                                    value={widthMM}
                                    onChange={(e) => setWidthMM(e.target.value)}
                                  />
                                </div>
                                <div className="input">
                                  <label>Unit Height (mm)</label>
                                  <input
                                    type="text"
                                    placeholder=""
                                    value={heightMM}
                                    onChange={(e) =>
                                      setHeightMM(e.target.value)
                                    }
                                  />
                                </div>
                                <div className="input">
                                  <label>Unit Length (mm)</label>
                                  <input
                                    type="text"
                                    placeholder=""
                                    value={lengthMM}
                                    onChange={(e) =>
                                      setLengthMM(e.target.value)
                                    }
                                  />
                                </div>

                                <div className="input">
                                  <label>CTN Width (mm)</label>
                                  <input
                                    type="text"
                                    placeholder=""
                                    value={ctnWidthMM}
                                    onChange={(e) =>
                                      setCtnWidthMM(e.target.value)
                                    }
                                  />
                                </div>
                                <div className="input">
                                  <label>CTN Height (mm)</label>
                                  <input
                                    type="text"
                                    placeholder=""
                                    value={ctnHeightMM}
                                    onChange={(e) =>
                                      setCtnHeightMM(e.target.value)
                                    }
                                  />
                                </div>
                                <div className="input">
                                  <label>CTN Length (mm)</label>
                                  <input
                                    type="text"
                                    placeholder=""
                                    value={ctnLengthMM}
                                    onChange={(e) =>
                                      setCtnLengthMM(e.target.value)
                                    }
                                  />
                                </div>

                                <div className="input">
                                  <label>Ingredients</label>
                                  <input
                                    type="text"
                                    placeholder=""
                                    value={ingredients}
                                    onChange={(e) =>
                                      setIngredients(e.target.value)
                                    }
                                  />
                                </div>
                              </div>
                              <div>
                                <div className="input">
                                  <div className="photo_grid">
                                    {addedPhotos.length > 0 &&
                                      addedPhotos.map((item) => (
                                        <div className="img_over" key={item.id}>
                                          <img
                                            src={`https://api-master.notionsgroup.com/uploads/${item}`}
                                            alt=""
                                          />

                                          <div className="trash_icon">
                                            <button
                                              onClick={(e) =>
                                                removePhoto(e, item)
                                              }
                                            >
                                              <DeleteOutlineIcon
                                                style={{ fontSize: "medium" }}
                                              />
                                            </button>
                                          </div>
                                          <div className="star_icon">
                                            <button
                                              onClick={(e) =>
                                                selectMainPhoto(e, item)
                                              }
                                            >
                                              {item === addedPhotos[0] && (
                                                <StarIcon
                                                  style={{
                                                    fontSize: "medium",
                                                    color: "orange",
                                                  }}
                                                />
                                              )}
                                              {item !== addedPhotos[0] && (
                                                <StarBorderIcon
                                                  style={{ fontSize: "medium" }}
                                                />
                                              )}
                                            </button>
                                          </div>
                                        </div>
                                      ))}
                                    <label>
                                      <input
                                        onChange={uploadPhoto}
                                        type="file"
                                        multiple
                                        className="btn_upload"
                                      />
                                      Upload Images
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="save">
                              <button>
                                <span>Save</span>
                              </button>
                            </div>
                          </div>
                          <Link to={"/inventory"}>
                            <div className="close">
                              <span onClick={handleEdit}>X</span>
                            </div>
                          </Link>
                        </form>
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
          <div className="table_head">
            {showPro && (
              <div className="addPro_in">
                <div className="addNew_wrap">
                  <div>
                    <div className="pro_head">
                      <h1>Product Information</h1>
                      {/* <p>{itemId}</p> */}
                    </div>
                    <div style={{ overflow: "scroll", maxHeight: "600px" }}>
                      <div className="product_info_wrap">
                        <div className="_product_item">
                          <div className="image_list">
                            {singalProductData.addedPhotos?.map(
                              (item, index) => (
                                <div className="image_wrap_in" key={index}>
                                  <img
                                    src={`https://api-master.notionsgroup.com/uploads/${item}`}
                                    alt=""
                                  />
                                  <button
                                    onClick={() =>
                                      handleDownload(
                                        `https://api-master.notionsgroup.com/uploads/${item}`
                                      )
                                    }
                                  >
                                    Download
                                  </button>
                                </div>
                              )
                            )}
                          </div>
                        </div>
                        <div>
                          <>
                            <div className="btnWrap">
                              <MdOutlineEdit
                                onClick={handleEdit}
                                size={20}
                                color="gray"
                              />
                            </div>
                            <div className="product_pro">
                              <div className="product_item">
                                <label>Title</label>
                                <p>{singalProductData.title}</p>
                              </div>

                              <div className="product_item">
                                <label>Price</label>
                                <p>{singalProductData.price}</p>
                              </div>

                              <div className="product_item">
                                <label>Weight</label>
                                <p>{singalProductData.weight}</p>
                              </div>
                              <div className="product_item">
                                <label>Unit</label>
                                <p>{singalProductData.unit}</p>
                              </div>
                              <div className="product_item">
                                <label>Type</label>
                                <p>{singalProductData.type}</p>
                              </div>
                              <div className="product_item">
                                <label>Category</label>
                                <p>{singalProductData.category}</p>
                              </div>
                              <div className="product_item">
                                <label>Sub Category</label>
                                <p>{singalProductData.subCategory}</p>
                              </div>
                              <div className="product_item">
                                <label>Brand</label>
                                <p>{singalProductData.brand}</p>
                              </div>
                              <div className="product_item">
                                <label>Brand Owner</label>
                                <p>{singalProductData.brandOwner}</p>
                              </div>
                              <div className="product_item">
                                <label>Base</label>
                                <p>{singalProductData.base}</p>
                              </div>
                              <div className="product_item">
                                <label>Filling</label>
                                <p>{singalProductData.filling}</p>
                              </div>
                              <div className="product_item">
                                <label>Coating</label>
                                <p>{singalProductData.coating}</p>
                              </div>
                              <div className="product_item">
                                <label>WET Rang</label>
                                <p>{singalProductData.wetRang}</p>
                              </div>
                              <div className="product_item">
                                <label>SKU</label>
                                <p>{singalProductData.sku}</p>
                              </div>
                              <div className="product_item">
                                <label>SKU Name English</label>
                                <p>{singalProductData.nameEnglish}</p>
                              </div>
                              <div className="product_item">
                                <label>SKU Name Arbic</label>
                                <p>{singalProductData.nameArbic}</p>
                              </div>
                              <div className="product_item">
                                <label>Barcode - Pouch (100g)</label>
                                <p>{singalProductData.barcodePouch}</p>
                              </div>
                              <div className="product_item">
                                <label>Barcode - Case</label>
                                <p>{singalProductData.barcodeCase}</p>
                              </div>

                              <div className="product_item">
                                <label>Claims 1</label>
                                <p>{singalProductData.claimsOne}</p>
                              </div>
                              <div className="product_item">
                                <label>Claims 2</label>
                                <p>{singalProductData.claimsTwo}</p>
                              </div>
                              <div className="product_item">
                                <label>Claims 3</label>
                                <p>{singalProductData.claimsThree}</p>
                              </div>
                              <div className="product_item">
                                <label>Benefits</label>
                                <p>{singalProductData.benefits}</p>
                              </div>
                              <div className="product_item">
                                <label>Product Description English</label>
                                <p>{singalProductData.proDesEnglish}</p>
                              </div>
                              <div className="product_item">
                                <label>Product Description Arbic</label>
                                <p>{singalProductData.proDesArbic}</p>
                              </div>
                              <div className="product_item">
                                <label>Dimensions per Case</label>
                                <p>{singalProductData.dimensionsPerCase}</p>
                              </div>
                              <div className="product_item">
                                <label>Storage Instructions</label>
                                <p>{singalProductData.storageInstructions}</p>
                              </div>
                              <div className="product_item">
                                <label>Shelf Life</label>
                                <p>{singalProductData.shelfLife}</p>
                              </div>

                              <div className="product_item">
                                <label>Unit Width (mm)</label>
                                <p>{singalProductData.widthMM}</p>
                              </div>
                              <div className="product_item">
                                <label>Unit Height (mm)</label>
                                <p>{singalProductData.heightMM}</p>
                              </div>
                              <div className="product_item">
                                <label>Unit Length (mm)</label>
                                <p>{singalProductData.lengthMM}</p>
                              </div>
                              <div className="product_item">
                                <label>CTN Width (mm)</label>
                                <p>{singalProductData.ctnWidthMM}</p>
                              </div>
                              <div className="product_item">
                                <label>CTN Height (mm)</label>
                                <p>{singalProductData.ctnHeightMM}</p>
                              </div>
                              <div className="product_item">
                                <label>CTN Length (mm)</label>
                                <p>{singalProductData.ctnLengthMM}</p>
                              </div>
                              <div className="product_item">
                                <label>Ingredients</label>
                                <p>{singalProductData.ingredients}</p>
                              </div>
                            </div>
                            <div className="btnWrap">
                              <RiDeleteBin5Line
                                onClick={deleteItem}
                                size={20}
                                color="red"
                              />
                            </div>
                          </>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Link to={"/inventory"}>
                    <div className="_close">
                      <span onClick={handleProfile}>X</span>
                    </div>
                  </Link>
                </div>
              </div>
            )}
          </div>

          <DataGrid
            key={productData._id}
            onCellClick={handleCellClick}
            rows={productData}
            columns={columns}
            pageSize={5}
            over
            disableRowSelectionOnClick
            initialState={{
              pagination: {
                paginationModel: {
                  //pageSize:
                },
              },
            }}
            getRowId={(row) => row._id}
            slots={{ toolbar: GridToolbar }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 500 },
              },
            }}
          />
        </div>
      </div>
      
    </div>
  );
};

export default Inventory;
