import React from 'react'
import './subHeader.scss'
import { Link } from 'react-router-dom';

const SubHeader = () => {
  return (
    <div className="subHeader">
      <ul>
        <Link to={"/dashboard"}>
          <li>
            <p> Dashboard</p>{" "}
          </li>
        </Link>
        <Link to={"/inventory"}>
          <li>
            {" "}
            <p>Inventory</p>
          </li>
        </Link>
        {/* <Link to={"/list"}>
          <li>
            {" "}
            <p>List</p>
          </li>
        </Link> */}
        <Link to={"/orders"}>
          <li>
            {" "}
            <p>Orders</p>
          </li>
        </Link>
      </ul>
    </div>
  );
}

export default SubHeader
