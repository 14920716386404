import React, { useEffect, useState } from "react";
import "./orders.scss";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import { RiDeleteBin5Line } from "react-icons/ri";
import { Link, useNavigate, useParams } from "react-router-dom";

const Orders = () => {
  const { itemId } = useParams();
  const navigate = useNavigate();
  const [productData, setProductData] = useState([]);
  const [singalOrder, setSingalOrder] = useState([]);

  const [showPro, setShowPro] = useState(false);

  const handleProfile = () => {
    setShowPro(!showPro);
  };

  const handleCellClick = (params) => {
    const placesId = params.row?._id;
    const url = `/orders/${placesId}`;
    navigate(url);
    setShowPro(!showPro);
  };

  const getAllOrders = async () => {
    try {
      const response = await axios.get("/get_all_orders");
      const sortedData = response.data.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );
      setProductData(sortedData);
    } catch (error) {
      console.log(error);
    }
  };

 useEffect(() => {
   if (!itemId) {
     return;
   }
   const getsingalOrderData = async () => {
     try {
       const response = await axios.get(`/getSingalOrder/${itemId}`);
       setSingalOrder(response?.data);
     } catch (error) {
       if (error.response && error.response.status === 404) {
         console.log("Order not found");
       } else {
         console.log("An error occurred:", error.message);
       }
     }
   };
   getsingalOrderData();
 }, [itemId]);


  useEffect(() => {
    getAllOrders();
  }, []);

  //   console.log(productData);

  // Define columns for the DataGrid
  const columns = [
    { field: "brand", headerName: "Brand", width: 120 },
    { field: "customer_reference_number", headerName: "CRN", width: 150 },
    { field: "destination_address_name", headerName: "Name", width: 150 },
    {
      field: "destination_address_mobile_number",
      headerName: "Contact",
      width: 150,
    },
    { field: "card_amount", headerName: "Amount", width: 100 },
    { field: "createdAt", headerName: "Order Date", width: 150 },
    {
      field: "destination_address_city",
      headerName: "City",
    },
    {
      field: "num_pieces",
      headerName: "Qut",
    },
    {
      field: "weight",
      headerName: "Weight",
    },
  ];

  return (
    <div className="order_main">
      <div className="order">
        <div className="table">
          <div className="table_head">
            {showPro && (
              <div className="addPro_in">
                <div className="addNew_wrap">
                  <div>
                    <div className="pro_head">
                      <h2>Order summary</h2>
                      {/* <p>{itemId}</p> */}
                    </div>
                    <br />
                    <div
                      style={{
                        overflow: "scroll",
                        maxHeight: "600px",
                        padding: "30px",
                      }}
                    >
                      <div className="product_info_wrap">
                        <div>
                          <>
                            <div className="product_pro">
                              <div className="customer">
                                <div className="customer_in">
                                  <h4>Customer</h4>
                                </div>
                                <div className="customer_in">
                                  <p>
                                    {singalOrder.customer_reference_number}{" "}
                                    <br />
                                    {singalOrder.destination_address_name}{" "}
                                    <br />
                                    {singalOrder.destination_address_house_no}
                                    {
                                      singalOrder.destination_address_building_name
                                    }
                                    {singalOrder.destination_address_area}{" "}
                                    <br />
                                    {singalOrder.destination_address_city}{" "}
                                    <br />
                                    {
                                      singalOrder.destination_address_mobile_number
                                    }{" "}
                                  </p>
                                </div>
                              </div>
                              <div className="customer_ad">
                                <div className="customer_in">
                                  <h4>Shipping details</h4>
                                </div>
                                <div className="customer_in">
                                  <p>
                                    {singalOrder.destination_address_name}{" "}
                                    <br />
                                    {singalOrder.destination_address_house_no}
                                    {
                                      singalOrder.destination_address_building_name
                                    }
                                    {singalOrder.destination_address_area}{" "}
                                    <br />
                                    {singalOrder.destination_address_city}{" "}
                                    <br />
                                    {
                                      singalOrder.destination_address_mobile_number
                                    }{" "}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </>
                          <div>
                            <table>
                              <tr>
                                <th></th>
                                <th>ITEMS</th>
                                <th>QTY</th>
                                <th>AMOUNT</th>
                              </tr>
                              {singalOrder.products?.map((item, i) => (
                                <tr key={i}>
                                  <th>{i + 1}</th>
                                  <td>{item.title}</td>
                                  <td>{item.quantity}</td>
                                  <td>{item.price} AED</td>
                                </tr>
                              ))}
                              <tr>
                                <th></th>
                                <td>Shipping (Free Delivery)</td>
                                <td></td>
                                <td>00 AED</td>
                              </tr>
                              <tr>
                                <th>Total</th>
                                <td></td>
                                <td>{singalOrder.num_pieces}</td>
                                <th>{singalOrder.card_amount} AED</th>
                              </tr>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Link to={"/orders"}>
                    <div className="_close">
                      <span onClick={handleProfile}>X</span>
                    </div>
                  </Link>
                </div>
              </div>
            )}
          </div>
          <DataGrid
            key={productData._id}
            onCellClick={handleCellClick}
            rows={productData}
            columns={columns}
            pageSize={5}
            disableRowSelectionOnClick
            initialState={{
              pagination: {
                paginationModel: {
                  // pageSize: 5,
                },
              },
            }}
            getRowId={(row) => row._id}
            slots={{ toolbar: GridToolbar }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 500 },
              },
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Orders;
