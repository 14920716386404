import React, { useState } from "react";
import "./dashBoard.scss";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import Chart from "react-apexcharts";
import { Doughnut } from "react-chartjs-2";
import CityCarousel from "../../components/carousel/CityCarousel";

ChartJS.register(ArcElement, Tooltip, Legend);
export const data = {
  labels: ["Apple", "Knorr", "Shoop", "Green", "Purple", "Orange"],
  datasets: [
    {
      label: "# of Votes",
      data: [0, 1, 5, 8, 9, 15],
      backgroundColor: [
        "rgba(255, 99, 132, 0.2)",
        "rgba(54, 162, 235, 0.2)",
        "rgba(255, 206, 86, 0.2)",
        "rgba(75, 192, 192, 0.2)",
        "rgba(153, 102, 255, 0.2)",
        "rgba(255, 159, 64, 0.2)",
      ],
      borderColor: [
        "rgba(255, 99, 132, 1)",
        "rgba(54, 162, 235, 1)",
        "rgba(255, 206, 86, 1)",
        "rgba(75, 192, 192, 1)",
        "rgba(153, 102, 255, 1)",
        "rgba(255, 159, 64, 1)",
      ],
      borderWidth: 0,
    },
  ],
};

const Dashboard = () => {
  const [chart, setChart] = useState({
    options: {
      chart: {
        id: "basic-bar",
      },
      xaxis: {
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
      },
    },
    series: [
      {
        name: "series",
        data: [10, 20, 40, 50, 60, 20, 10, 35, 45, 70, 25, 70],
      },
    ],
  });

  return (
    <div className="dashboard_main">
      <div className="dashBoard">
        <>
          <h2>Inventory Stats</h2>
          <div className="dashBoard_in">
            <div className="dashBoard_item">
              <p>Total Products</p>
              <h2>0</h2>
            </div>
            <div className="dashBoard_item">
              <p>Total Store Value</p>
              <h2>00.00 AED</h2>
            </div>
            <div className="dashBoard_item">
              <p>Out of Stoke</p>
              <h2>00</h2>
            </div>
            <div className="dashBoard_item">
              <p>All Category</p>
              <h2>0</h2>
            </div>
          </div>
        </>
        <div div className="dashBoard_data_main">
          <h2>Inventory Items</h2>
          <div className="dashBoard_data">
            <div>
              <Chart
                options={chart.options}
                series={chart.series}
                type="bar"
                width="800"
                height="400"
              />
            </div>
            <div>
              <Doughnut data={data} />
            </div>
          </div>
        </div>
        <CityCarousel />
      </div>
    </div>
  );
};

export default Dashboard;
