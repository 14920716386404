import React from 'react'
import "./layout.scss";
import Header from '../header/Header';
import SideBar from '../sideBar/SideBar';
import { Outlet } from 'react-router-dom';
import SubHeader from '../subHeader/SubHeader';

const Layout = () => {
  return (
    <>
      <div className="layout_head">
        <Header />
        <SubHeader/>
      </div>
      <div className="layout_in">

        <Outlet />
      </div>
    </>
  );
}

export default Layout
