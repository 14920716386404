import React, { useContext } from 'react'
import "./header.scss";
import axios from 'axios';
import { UserContext } from '../../UserContext';
import { useNavigate } from 'react-router-dom';


const Header = () => {

   const { user, setUser } = useContext(UserContext);
     const navigate = useNavigate();

  async function logout() {
    await axios.post("/logout");
    navigate("/login");
    setUser(null);
  }

  return (
    <div className="header">
      <div className="header_in">
        <span>Notions Online Stocks Management</span>
      </div>
      <div className="header_in">
        <button className="btn_logout" onClick={logout}>
          Logout
        </button>
      </div>
    </div>
  );
}

export default Header
