import React, { useContext, useState } from "react";
import "./login.scss";
import { Link, Navigate, useNavigate } from "react-router-dom";
import axios from "axios";
import { UserContext } from "../../UserContext";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const { setUser } = useContext(UserContext);

  const handLogin = async (ev) => {
    ev.preventDefault();

    try {
      const response = await axios.post("/login", {
        email,
        password,
      });
      // console.log(response.data);
      localStorage.setItem("userData", JSON.stringify(response.data));
      setUser(response.data);

      navigate("/dashboard");
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="__fw">
      <div className="__mw">
        <div className="login">
          <div className="_log_in">
            <div className="_in_h">
              <h2>Welcome Back</h2>
              <span>Login to continue</span>
            </div>
            <form onSubmit={handLogin}>
              <input
                required
                type="email"
                placeholder="your@email.com"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <input
                required
                type="password"
                placeholder="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <button>Login</button>
              {/* <div className="_rg_pg">
                <span>
                  Don't have an account yet?{" "}
                  <Link to={"/register"}> Register now</Link>
                </span>
              </div> */}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
