import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Dashboard from "./pages/dashboard/Dashboard";
import axios from "axios";
import Layout from "./components/layout/Layout";
import Inventory from "./pages/inventory/Inventory";
import Login from "./pages/login/Login";
import PrivateRoutes from "./components/PrivateRoutes.js";
import Register from "./pages/register/Register.jsx";
import { UserContextProvider } from "./UserContext.jsx";
import List from "./pages/list/List.jsx";
import Orders from "./pages/orders/Orders.jsx";

// axios.defaults.baseURL = "http://localhost:4343/api/v1";
axios.defaults.baseURL = "https://api-master.notionsgroup.com/api/v1";
axios.defaults.withCredentials = true;

const App = () => {
  return (
    <UserContextProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route element={<PrivateRoutes />}>
            <Route path="/" element={<Layout />}>
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/inventory" element={<Inventory />} />
              <Route path="/inventory/:itemId" element={<Inventory />} />
              <Route path="/list" element={<List />} />
              <Route path="/orders" element={<Orders />} />
              <Route path="/orders/:itemId" element={<Orders />} />
            </Route>
          </Route>
          <Route exact path="*" element={<Login />} />
        </Routes>
      </BrowserRouter>
    </UserContextProvider>
  );
};

export default App;
