import axios from "axios";
import { createContext, useEffect, useState } from "react";

export const UserContext = createContext({});

export function UserContextProvider({ children }) {
  const [user, setUser] = useState(null);
  const [ready, setReady] = useState(false);

   useEffect(() => {
     if (!user) {
       axios
         .get("/profile")
         .then(({ data }) => {
           setUser(data);
           setReady(true);
         })
         .catch((error) => {
           // Handle error, e.g., setReady(true) or display an error message
           console.error("Error fetching user profile:", error);
           setReady(true);
         });
     }
     // Remove this line unless it's necessary for your logic
   }, [user]); 


   const login = () => {
     const userData = localStorage.getItem("userData");
    //  console.log(userData);
     if (userData) {
       setUser(JSON.parse(userData));
     }
   };

   const logout = () => {
     localStorage.removeItem("userData");
     setUser(null);
   };

   

  return (
    <UserContext.Provider value={{ user, setUser, ready, login, logout }}>
      {children}
    </UserContext.Provider>
  );
}
