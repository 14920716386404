import React, { useState } from 'react'
import './list.scss'
import axios from 'axios';

const List = () => {
    const [items, setItems] = useState([
      { name: "", description: "", quantity: 0 },
    ]);

    console.log(items)

    const handleChange = (index, event) => {
      const values = [...items];
      values[index][event.target.name] = event.target.value;
      setItems(values);
    };

    const handleAddFields = () => {
      setItems([...items, { name: "", description: "", quantity: 0 }]);
    };

    const handleRemoveFields = (index) => {
      const values = [...items];
      values.splice(index, 1);
      setItems(values);
    };

     const handleSubmit = async (event) => {
       event.preventDefault();
       try {
         const response = await axios.post(
           "http://localhost:5000/api/items/add-items",
           { items }
         );
         console.log(response.data);
       } catch (error) {
         console.error("There was an error!", error);
       }
     };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        {items.map((item, index) => (
          <div key={index}>
            <input
              name="name"
              placeholder="Name"
              value={item.name}
              onChange={(event) => handleChange(index, event)}
            />
            <input
              name="description"
              placeholder="Description"
              value={item.description}
              onChange={(event) => handleChange(index, event)}
            />
            <input
              name="quantity"
              type="number"
              placeholder="Quantity"
              value={item.quantity}
              onChange={(event) => handleChange(index, event)}
            />
            <button type="button" onClick={() => handleRemoveFields(index)}>
              Remove
            </button>
          </div>
        ))}
        <button type="button" onClick={handleAddFields}>
          Add Item
        </button>
        <button type="submit">Submit</button>
      </form>
    </div>
  );
}

export default List
